<!--  -->
<template>
  <div class="content">
    <div class="inner">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>活动管理</el-breadcrumb-item>
          <el-breadcrumb-item>活动列表</el-breadcrumb-item>
          <el-breadcrumb-item>活动详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="info">
        <div class="one">
          <div class="key">
            <img :src="data.imgUrl" alt />
          </div>
          <div class="val">
            <div class="tit">{{data.name}}</div>
<!--            <p>为保证数据的及时性，每5-10分钟刷新一次。在归档主流的新闻平台的内容数据基础上,对外提供统一的调用方式来完成对实时最新的相关新闻的获取，极大方便各类企业在自有软件中集成新闻内容的功能。</p>-->
            <p v-html="data.info"></p>
<!--            <div class="tag">新闻实报</div>-->
          </div>
        </div>
        <div class="one two">
          <div class="key right">资源包规格：</div>

          <div class="val">
            <div class="num">
              <div :class="['item',{'choose':current==index}]" v-for="(item,index)  in specs" @click="switchSpecs(item,index)" :key="index">{{item.count}}次</div>
<!--              <div class="item">1000次</div>-->
<!--              <div class="item">1000次</div>-->
            </div>
          </div>


        </div>
        <div class="one two">
          <div class="key right">价格：</div>
          <div class="val price">
            <p>
              <span>￥</span> {{ specsNow.price }}
            </p>
            自购买<span class="day">{{specsNow.day}}</span>天内有效
          </div>
        </div>
        <div class="one">
          <div class="key"></div>
          <div class="val">
<!--            <btn-->
<!--              type="primary"-->
<!--              buttonname="立即购买"-->
<!--              :buttonstyle="style"-->
<!--              @click="$store.state.token?jump('/purchase/index'):jump('/login')"-->
<!--            ></btn>-->

              <btn
                type="primary"
                buttonname="立即购买"
                :buttonstyle="style"
                @click="$store.state.token?toPay():jump('/login')"
              ></btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getFaceInfo,getSpecsInfo,saveOrder} from "@/api/frontApi";

export default {
  data () {
    return {
      data:{},//数据
      specs:{},//规格
      specsNow:{},//选中了的规格
      current:0,//选中了的规格下标 默认第一个
      style: {
        'width': '150px',
        'height': '45px',
      }
    };
  },

  components: {},

  computed: {},

  mounted: {},
  created() {
    getFaceInfo({id:this.$route.query.id}).then(res => {
      console.log(res)
      this.data = res
    })
    getSpecsInfo({goodsId:this.$route.query.id}).then(res=>{
      console.log(res)
      this.specs = res
      this.specsNow = this.specs[0]
    })
  },
  methods: {
    switchSpecs(item,index) { //切换规格
        this.current = index
        this.specsNow = item
    },
    toPay(){ //生成订单——>拉起支付
      //生成订单  //TODO 需要获取用户ID。。。 this.user.id
      const order = {
        memberId: 'xx',
        orderPrice: this.specsNow.price,//订单金额
        shopId: this.data.id,
        shopSpecsId: this.specsNow.id,
        type: 1,//1 接口
      }
      console.log(order)
      saveOrder(order).then(res=>{ //保存订单
        console.log(res)
      })
    },
    jump (path) {
      this.$router.push(path)
    }
  }
}

</script>
<style lang='scss' scoped>
.content {
  background: $bg;
  .inner {
    padding: 20px 0;
    max-width: $minWidth;
    margin: 0 auto;
    .breadcrumb {
      margin-bottom: 20px;
      color: #fff;
    }
    .info {
      padding: 20px;
      background: #fff;
      .one {
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
        .key {
          padding-right: 10px;
          width: 140px;
          img {
            width: 100px;
            height: 100px;
          }
        }
        .right {
          font-size: 16px;
          color: #666666;
          text-align: right;
        }
        .val {
          width: 0;
          flex: 1;
          .tit {
            font-size: 18px;
            color: #191c3d;
            margin-bottom: 15px;
          }
          p {
            margin-bottom: 10px;
            color: #75778b;
            line-height: 1.6;
          }
          .tag {
            display: inline-block;
            padding: 4px 10px;
            color: #999999;
            border: 1px solid #c2c2c6;
            border-radius: 4px;
            font-size: 12px;
          }
          .num {
            width: 100%;
            display: flex;
            align-items: center;
            .item {
              font-size: 14px;
              padding: 6px 10px;
              margin-right: 10px;
              color: #999;
              border: 1px solid #e5e7ec;
            }
            .choose {
              position: relative;
              color: #2468f2;
              border: 1px solid #2468f2;
            }
          }
        }
      }
      .two {
        align-items: center;
        .price {
          p {
            margin: 0;
            padding: 10px;
            color: #ff624e;
            font-size: 20px;
            background: #f5f8ff;
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
